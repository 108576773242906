
/* THIN */
@font-face {
  font-family: 'Poppins';
  src: url('Poppins-ThinItalic.ttf')  format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Thin.ttf')  format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* EXTRA-LIGHT */
@font-face {
  font-family: 'Poppins';
  src: url('Poppins-ExtraLightItalic.ttf')  format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-ExtraLight.ttf')  format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* LIGHT */
@font-face {
  font-family: 'Poppins';
  src: url('Poppins-LightItalic.ttf')  format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Light.ttf')  format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* REGULAR */
@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Italic.ttf')  format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Regular.ttf')  format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* MEDIUM */
@font-face {
  font-family: 'Poppins';
  src: url('Poppins-MediumItalic.ttf')  format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Medium.ttf')  format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* SEMI-BOLD */
@font-face {
  font-family: 'Poppins';
  src: url('Poppins-SemiBoldItalic.ttf')  format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-SemiBold.ttf')  format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* BOLD */
@font-face {
  font-family: 'Poppins';
  src: url('Poppins-BoldItalic.ttf')  format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* EXTRA-BOLD */
@font-face {
  font-family: 'Poppins';
  src: url('Poppins-ExtraBoldItalic.ttf')  format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-ExtraBold.ttf')  format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* BLACK */
@font-face {
  font-family: 'Poppins';
  src: url('Poppins-BlackItalic.ttf')  format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Black.ttf')  format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}