
/* THIN */
@font-face {
  font-family: 'Roboto';
  src: url('Roboto-ThinItalic.ttf')  format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Thin.ttf')  format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}


/* LIGHT */
@font-face {
  font-family: 'Roboto';
  src: url('Roboto-LightItalic.ttf')  format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Light.ttf')  format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* REGULAR */
@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Italic.ttf')  format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Regular.ttf')  format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* MEDIUM */
@font-face {
  font-family: 'Roboto';
  src: url('Roboto-MediumItalic.ttf')  format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Medium.ttf')  format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* BOLD */
@font-face {
  font-family: 'Roboto';
  src: url('Roboto-BoldItalic.ttf')  format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* BLACK */
@font-face {
  font-family: 'Roboto';
  src: url('Roboto-BlackItalic.ttf')  format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Black.ttf')  format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}